
import Vue, { PropOptions } from "vue";


interface Visual {
  id: number;
  Media: [{ url: string }];
  Title: string | null;
}

export default Vue.extend({
  props: {
    visual: {
      type: Object,
      required: true,
    } as PropOptions<Visual>,
  },
});
